import { Link } from "gatsby";
import Wrap from "../components/wrap";
import PropTypes from "prop-types";
import React, { useState } from "react";
function AboutNav() {

  return (
    <nav className="pt-3 text-center bg-jungle-300 bg-leaf-2 bg-edge bg-edge--be-300">
      <ul className="mx-auto inline-flex border-jungle-400 px-8 text-sm xl:text-base">
        <li>
          <Link
            to="/about/"
            className="py-4 no-underline block text-white mx-4 hover:text-jungle-200"
            activeClassName="text-jungle-200">
            Over ons
          </Link>
        </li>
        <li>
          <Link
            to="/about/toekomst"
            className="py-4 no-underline block text-white mx-4 hover:text-jungle-200"
            activeClassName="text-jungle-200">
            Toekomst
          </Link>
        </li>
        <li>
          <Link
            to="/about/partners"
            className="py-4 no-underline block text-white mx-4 hover:text-jungle-200"
            activeClassName="text-jungle-200">
            Partners
          </Link>
        </li>
        <li>
          <Link
            to="/about/mensen"
            className="py-4 no-underline block text-white mx-4 hover:text-jungle-200"
            activeClassName="text-jungle-200">
            Mensen
          </Link>
        </li>
      </ul>
    </nav>
  );
}

AboutNav.propTypes = {

};

AboutNav.defaultProps = {

};

export default AboutNav;
