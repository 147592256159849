import React from "react";
import { graphql } from "gatsby";

import AboutNav from "../components/aboutnav";
import Wrap from "../components/wrap";
import SEO from "../components/seo";

import LocalizedLink from "../components/localizedLink";

import { useTranslation } from "react-i18next";

const newsPage = ({ data: { allMdx } }) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="News" keywords={[`foo`, `bar`, `zar`]} />

      <div className="xl:bg-leaf-3">
        <Wrap maxWidth="max-w-2xl">
          <h1 className="c-h1">{t("news.title")}</h1>
          <p className="c-p">{t("news.intro")}</p>
          <ul className="border-t">
            {allMdx.edges.map(({ node: post }) => (
              <li
                key={`${post.frontmatter.title}-${post.fields.locale}`}
                className="mb-4 border-b py-8"
              >
                <h2 className="c-h2">
                  <LocalizedLink to={`/${post.parent.relativeDirectory}`}>
                    {post.frontmatter.title}
                  </LocalizedLink>
                </h2>
                <p className="c-caps text-jungle-300">
                  {post.frontmatter.location} &mdash; {post.frontmatter.date}
                </p>
                <p className="c-p mb-0">{post.frontmatter.intro}</p>
              </li>
            ))}
          </ul>
        </Wrap>
      </div>
    </>
  );
};

export default newsPage;

export const query = graphql`
  query newsPageQuery($locale: String!, $dateFormat: String!) {
    allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { templateKey: { eq: "post" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            intro
            date(formatString: $dateFormat)
            location
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`;
